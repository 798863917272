<template>
  <NuxtPage />
</template>

<script setup lang="ts">
const route = useRoute();

if (route.matched.length === 2) {
  navigateTo("/login/magic-link/request");
}
</script>
